import { ErrorList } from '@/components/desktop/common/ErrorList';
import { standardServerErrorHandler } from '@/src/utils';
import { notification } from 'antd';
import { useCallback } from 'react';

/**
 *
 * @returns
 */
export const useErrorHandler = (customHandler?: (error: Error) => void) => {
  return useCallback(
    (error: Error) => {
      try {
        if (customHandler) {
          customHandler(error);
        }
      } finally {
        standardServerErrorHandler(error, (message, errors) =>
          notification.error({
            message: message,
            description: <ErrorList errors={errors} />,
            duration: 0,
          })
        );
      }
    },
    [customHandler]
  );
};
