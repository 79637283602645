/* tslint:disable */
/* eslint-disable */
/**
 * 福間商事向け工程管理システム
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IndexVersionNotification200Response,
  IndexVersionNotificationQParameter,
  ReadAllVersionNotification200Response,
  ReadVersionNotification200Response,
  ServerError,
} from '../models/index';
import {
    IndexVersionNotification200ResponseFromJSON,
    IndexVersionNotification200ResponseToJSON,
    IndexVersionNotificationQParameterFromJSON,
    IndexVersionNotificationQParameterToJSON,
    ReadAllVersionNotification200ResponseFromJSON,
    ReadAllVersionNotification200ResponseToJSON,
    ReadVersionNotification200ResponseFromJSON,
    ReadVersionNotification200ResponseToJSON,
    ServerErrorFromJSON,
    ServerErrorToJSON,
} from '../models/index';

export interface IndexVersionNotificationRequest {
    page?: number;
    pageSize?: number;
    q?: IndexVersionNotificationQParameter;
}

export interface ReadVersionNotificationRequest {
    id: number;
}

/**
 * 
 */
export class VersionNotificationApi extends runtime.BaseAPI {

    /**
     * 変更通知一覧取得
     */
    async indexVersionNotificationRaw(requestParameters: IndexVersionNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IndexVersionNotification200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/version_notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IndexVersionNotification200ResponseFromJSON(jsonValue));
    }

    /**
     * 変更通知一覧取得
     */
    async indexVersionNotification(requestParameters: IndexVersionNotificationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IndexVersionNotification200Response> {
        const response = await this.indexVersionNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 通知の一括既読
     */
    async readAllVersionNotificationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadAllVersionNotification200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/version_notifications/read_all`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadAllVersionNotification200ResponseFromJSON(jsonValue));
    }

    /**
     * 通知の一括既読
     */
    async readAllVersionNotification(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadAllVersionNotification200Response> {
        const response = await this.readAllVersionNotificationRaw(initOverrides);
        return await response.value();
    }

    /**
     * 通知の既読
     */
    async readVersionNotificationRaw(requestParameters: ReadVersionNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadVersionNotification200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling readVersionNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/version_notifications/{id}/read`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadVersionNotification200ResponseFromJSON(jsonValue));
    }

    /**
     * 通知の既読
     */
    async readVersionNotification(requestParameters: ReadVersionNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadVersionNotification200Response> {
        const response = await this.readVersionNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
