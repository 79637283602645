import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

function getApiKey() {
  return document
    .getElementById('fukuma-bugsnag-api-key')
    ?.getAttribute('data-bugsnag-api-key');
}

function getReleaseStage() {
  return document
    .getElementById('fukuma-bugsnag-release_stage')
    ?.getAttribute('data-bugsnag-release_stage');
}

function initializeBugsnag(apiKey: string, releaseStage: string) {
  Bugsnag.start({
    apiKey: apiKey,
    releaseStage: releaseStage,
    enabledReleaseStages: ['development', 'staging', 'production'],
    plugins: [new BugsnagPluginReact(React)],
  });
}

export function getBugsnagErrorBoundary() {
  const apiKey = getApiKey();
  const releaseStage = getReleaseStage();

  if (apiKey && releaseStage) {
    initializeBugsnag(apiKey, releaseStage);
    return Bugsnag.getPlugin('react')?.createErrorBoundary(React);
  } else {
    console.warn(
      '[bugsnag]APIキーまたは、リリースステージが設定されていません。'
    );
  }
}
