/* tslint:disable */
/* eslint-disable */
/**
 * 福間商事向け工程管理システム
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import dayjs from 'dayjs';
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationBundle
 */
export interface NotificationBundle {
    /**
     * 
     * @type {number}
     * @memberof NotificationBundle
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationBundle
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationBundle
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationBundle
     */
    sourceUrl: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationBundle
     */
    updateUserName: string;
    /**
     * 
     * @type {dayjs.Dayjs}
     * @memberof NotificationBundle
     */
    sendAt: dayjs.Dayjs
    /**
     * 
     * @type {boolean}
     * @memberof NotificationBundle
     */
    isRead: boolean;
}

/**
 * Check if a given object implements the NotificationBundle interface.
 */
export function instanceOfNotificationBundle(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "sourceUrl" in value;
    isInstance = isInstance && "updateUserName" in value;
    isInstance = isInstance && "sendAt" in value;
    isInstance = isInstance && "isRead" in value;

    return isInstance;
}

export function NotificationBundleFromJSON(json: any): NotificationBundle {
    return NotificationBundleFromJSONTyped(json, false);
}

export function NotificationBundleFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationBundle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'body': json['body'],
        'sourceUrl': json['sourceUrl'],
        'updateUserName': json['updateUserName'],
        'sendAt': (dayjs(json['sendAt'])),
        'isRead': json['isRead'],
    };
}

export function NotificationBundleToJSON(value?: NotificationBundle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'body': value.body,
        'sourceUrl': value.sourceUrl,
        'updateUserName': value.updateUserName,
        'sendAt': (value.sendAt.format('YYYY-MM-DDTHH:mm:ss.SSSZ')),
        'isRead': value.isRead,
    };
}

