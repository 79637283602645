/* tslint:disable */
/* eslint-disable */
/**
 * 福間商事向け工程管理システム
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationBundle } from './NotificationBundle';
import {
    NotificationBundleFromJSON,
    NotificationBundleFromJSONTyped,
    NotificationBundleToJSON,
} from './NotificationBundle';

/**
 * 
 * @export
 * @interface ReadVersionNotification200Response
 */
export interface ReadVersionNotification200Response {
    /**
     * 
     * @type {NotificationBundle}
     * @memberof ReadVersionNotification200Response
     */
    notification: NotificationBundle;
}

/**
 * Check if a given object implements the ReadVersionNotification200Response interface.
 */
export function instanceOfReadVersionNotification200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "notification" in value;

    return isInstance;
}

export function ReadVersionNotification200ResponseFromJSON(json: any): ReadVersionNotification200Response {
    return ReadVersionNotification200ResponseFromJSONTyped(json, false);
}

export function ReadVersionNotification200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadVersionNotification200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notification': NotificationBundleFromJSON(json['notification']),
    };
}

export function ReadVersionNotification200ResponseToJSON(value?: ReadVersionNotification200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notification': NotificationBundleToJSON(value.notification),
    };
}

