/* eslint-disable @typescript-eslint/naming-convention */

import { theme } from 'antd';
import { Global } from '@emotion/react';

const { useToken } = theme;

export const GlobalStyle = () => {
  const { token } = useToken();

  const customStyles = {
    '.ant-descriptions-view': {
      background: `${token.colorBgContainer}`,
    },
    '.ellipsis-text': {
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      verticalAlign: 'bottom',
    },
  } as const;

  return <Global styles={customStyles} />;
};
