import React from 'react';
import { Typography, Space, List, Button, Row, Col, theme } from 'antd';
import { PiCheckCircle } from 'react-icons/pi';
import { t } from '@/src/locales/i18n';
import { NotificationBundle } from '@/api/models/NotificationBundle';
import { formatDateTime } from '@/src/utils';
import { buildApi } from '@/src/rails_helper';
import { VersionNotificationApi } from '@/api/apis/VersionNotificationApi';
import { useErrorHandler } from '@/src/hooks/desktop/useErrorHandler';
import { ICON_SIZES } from '@/src/constants/desktop/styleConstants';

type VersionNotificationListProps = {
  notifications?: NotificationBundle[];
  onRefresh: () => void;
};

const { useToken } = theme;

export const VersionNotificationList = ({
  notifications,
  onRefresh,
}: VersionNotificationListProps) => {
  const { token } = useToken();
  const { Link, Text } = Typography;
  const errorHandler = useErrorHandler();

  const markAsRead = (notificationId: number) => {
    buildApi(VersionNotificationApi)
      .readVersionNotification({
        id: notificationId,
      })
      .then(() => {
        onRefresh();
      })
      .catch(errorHandler);
  };

  return (
    <List
      loading={!notifications}
      dataSource={notifications}
      locale={{
        emptyText: (
          <Typography style={{ textAlign: 'center' }}>
            <Space direction="vertical">
              <PiCheckCircle
                style={{
                  fontSize: ICON_SIZES.large,
                  color: token.colorSuccess,
                }}
              />
              <Text>
                {t('components.common.version_notification_modal.no_data')}
              </Text>
            </Space>
          </Typography>
        ),
      }}
      renderItem={(notification) => (
        <List.Item>
          <Row style={{ width: '100%' }} align="middle">
            <Col span={18}>
              <Space direction="vertical">
                <Link
                  target="_blank"
                  href={notification.sourceUrl}
                  underline={true}
                >
                  {notification.title}
                </Link>
                <Text style={{ whiteSpace: 'pre-line' }}>
                  {notification.body}
                </Text>
                <Text>
                  {formatDateTime(notification.sendAt)}{' '}
                  {notification.updateUserName}
                </Text>
              </Space>
            </Col>
            <Col span={6} style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={() => markAsRead(notification.id)}
              >
                {t('actions.read')}
              </Button>
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};
