/* tslint:disable */
/* eslint-disable */
/**
 * 福間商事向け工程管理システム
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IndexVersionNotification200ResponseMeta } from './IndexVersionNotification200ResponseMeta';
import {
    IndexVersionNotification200ResponseMetaFromJSON,
    IndexVersionNotification200ResponseMetaFromJSONTyped,
    IndexVersionNotification200ResponseMetaToJSON,
} from './IndexVersionNotification200ResponseMeta';
import type { NotificationBundle } from './NotificationBundle';
import {
    NotificationBundleFromJSON,
    NotificationBundleFromJSONTyped,
    NotificationBundleToJSON,
} from './NotificationBundle';

/**
 * 
 * @export
 * @interface IndexVersionNotification200Response
 */
export interface IndexVersionNotification200Response {
    /**
     * 
     * @type {IndexVersionNotification200ResponseMeta}
     * @memberof IndexVersionNotification200Response
     */
    meta: IndexVersionNotification200ResponseMeta;
    /**
     * 
     * @type {Array<NotificationBundle>}
     * @memberof IndexVersionNotification200Response
     */
    notifications: Array<NotificationBundle>;
}

/**
 * Check if a given object implements the IndexVersionNotification200Response interface.
 */
export function instanceOfIndexVersionNotification200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "notifications" in value;

    return isInstance;
}

export function IndexVersionNotification200ResponseFromJSON(json: any): IndexVersionNotification200Response {
    return IndexVersionNotification200ResponseFromJSONTyped(json, false);
}

export function IndexVersionNotification200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndexVersionNotification200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': IndexVersionNotification200ResponseMetaFromJSON(json['meta']),
        'notifications': ((json['notifications'] as Array<any>).map(NotificationBundleFromJSON)),
    };
}

export function IndexVersionNotification200ResponseToJSON(value?: IndexVersionNotification200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': IndexVersionNotification200ResponseMetaToJSON(value.meta),
        'notifications': ((value.notifications as Array<any>).map(NotificationBundleToJSON)),
    };
}

