import React from 'react';
import { SPACING_PX } from '@/src/constants/desktop/styleConstants';

type ErrorListProps = {
  errors: string[];
};

export const ErrorList: React.FC<ErrorListProps> = ({
  errors,
}: ErrorListProps) => {
  if (errors.length === 0) {
    return <></>;
  }

  return (
    <ul style={{ paddingLeft: SPACING_PX.sm }}>
      {errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  );
};
