import React from 'react';
import {
  Modal,
  Typography,
  Space,
  Button,
  Row,
  Col,
  theme,
  Divider,
} from 'antd';
import { PiBell } from 'react-icons/pi';
import { t } from '@/src/locales/i18n';
import { VersionNotificationList } from './VersionNotificationModal/VersionNotificationList';
import { NotificationBundle } from '@/api/models/NotificationBundle';
import { buildApi } from '@/src/rails_helper';
import { VersionNotificationApi } from '@/api/apis/VersionNotificationApi';
import { indexVersionNotificationPath } from '@/src/get_routes';
import { useErrorHandler } from '@/src/hooks/desktop/useErrorHandler';
import { ICON_SIZES } from '@/src/constants/desktop/styleConstants';

type VersionNotificationModalProps = {
  isOpen: boolean;
  notifications?: NotificationBundle[];
  onCancel: () => void;
  onRefresh: () => void;
};

const { useToken } = theme;

export const VersionNotificationModal = ({
  isOpen,
  notifications,
  onCancel,
  onRefresh,
}: VersionNotificationModalProps) => {
  const { Title, Link } = Typography;
  const { token } = useToken();
  const errorHandler = useErrorHandler();

  const markAsAllRead = () => {
    buildApi(VersionNotificationApi)
      .readAllVersionNotification()
      .then(() => {
        onRefresh();
      })
      .catch(errorHandler);
  };

  return (
    <Modal
      centered
      title={
        <>
          <Row style={{ width: '100%' }} align="middle">
            <Col span={17}>
              <Title level={5} style={{ margin: 0 }}>
                <Space>
                  <PiBell style={{ fontSize: ICON_SIZES.medium }} />
                  {t('components.common.version_notification_modal.title')}
                </Space>
              </Title>
            </Col>
            <Col span={7}>
              <Button
                disabled={!notifications || notifications.length === 0}
                type="primary"
                onClick={() => markAsAllRead()}
              >
                {t('actions.read_all')}
              </Button>
            </Col>
          </Row>
          <Divider style={{ marginBottom: token.margin }} />
        </>
      }
      width={600}
      open={isOpen}
      onCancel={onCancel}
      footer={
        <>
          <Divider style={{ marginTop: token.margin }} />
          <div style={{ textAlign: 'center' }}>
            <Link
              target="_blank"
              href={indexVersionNotificationPath()}
              underline={true}
            >
              {t('components.common.version_notification_modal.show_all')}
            </Link>
          </div>
        </>
      }
    >
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <VersionNotificationList
          notifications={notifications}
          onRefresh={onRefresh}
        />
      </div>
    </Modal>
  );
};
