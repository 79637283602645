/* tslint:disable */
/* eslint-disable */
/**
 * 福間商事向け工程管理システム
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IndexVersionNotification200ResponseMeta
 */
export interface IndexVersionNotification200ResponseMeta {
    /**
     * 
     * @type {number}
     * @memberof IndexVersionNotification200ResponseMeta
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof IndexVersionNotification200ResponseMeta
     */
    unreadCount: number;
}

/**
 * Check if a given object implements the IndexVersionNotification200ResponseMeta interface.
 */
export function instanceOfIndexVersionNotification200ResponseMeta(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalCount" in value;
    isInstance = isInstance && "unreadCount" in value;

    return isInstance;
}

export function IndexVersionNotification200ResponseMetaFromJSON(json: any): IndexVersionNotification200ResponseMeta {
    return IndexVersionNotification200ResponseMetaFromJSONTyped(json, false);
}

export function IndexVersionNotification200ResponseMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndexVersionNotification200ResponseMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'unreadCount': json['unreadCount'],
    };
}

export function IndexVersionNotification200ResponseMetaToJSON(value?: IndexVersionNotification200ResponseMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'unreadCount': value.unreadCount,
    };
}

