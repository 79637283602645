import React, { useEffect, useState } from 'react';
import { Badge, Typography } from 'antd';
import { PiBell } from 'react-icons/pi';
import { buildApi } from '@/src/rails_helper';
import { VersionNotificationApi } from '@/api/apis/VersionNotificationApi';
import { NotificationBundle } from '@/api/models/NotificationBundle';
import { defaultIndexVersionNotificationPathPage } from '@/src/get_routes';
import { VersionNotificationModal } from './modal/VersionNotificationModal';
import { useErrorHandler } from '@/src/hooks/desktop/useErrorHandler';
import { ICON_SIZES } from '@/src/constants/desktop/styleConstants';

export const VersionNotificationModalIcon = () => {
  const [versionNotifications, setVersionNotifications] =
    useState<NotificationBundle[]>();
  const [unreadVersionNotificationCount, setUnreadVersionNotificationCount] =
    useState<number>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const errorHanlder = useErrorHandler();

  const showNotificationModal = () => {
    setIsNotificationModalOpen(true);
  };

  const handleNotificationModalCancel = () => {
    setIsNotificationModalOpen(false);
  };

  const handleNotificationModalRefresh = () => {
    fetchVersionNotifications();
  };

  const fetchVersionNotifications = () => {
    buildApi(VersionNotificationApi)
      .indexVersionNotification({
        page: defaultIndexVersionNotificationPathPage,
        pageSize: 5,
        q: { isReadEq: false },
      })
      .then((response) => {
        setVersionNotifications(response.notifications);
        setUnreadVersionNotificationCount(response.meta.unreadCount);
      })
      .catch(errorHanlder);
  };

  useEffect(() => {
    fetchVersionNotifications();
  }, []);

  return (
    <>
      <Typography onClick={showNotificationModal}>
        <Badge count={unreadVersionNotificationCount}>
          <PiBell style={{ color: 'white', fontSize: ICON_SIZES.medium }} />
        </Badge>
      </Typography>
      <VersionNotificationModal
        isOpen={isNotificationModalOpen}
        notifications={versionNotifications}
        onCancel={handleNotificationModalCancel}
        onRefresh={handleNotificationModalRefresh}
      />
    </>
  );
};
