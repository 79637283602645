/**
 * Zodの日本語エラーメッセージ
 */
import { ZodIssueCode, ZodIssueOptionalMessage, ErrorMapCtx } from 'zod';
import { t } from '@/src/locales/i18n';

let translateModuleKeys: string[] = [];

export const setTranslateModuleKeys = (keys: string[]) => {
  translateModuleKeys = keys;
};

function toSnakeCase(str: string) {
  return str
    .replace(/\.?([A-Z]+)/g, (x, y) => '_' + y.toLowerCase())
    .replace(/^_/, '');
}

export const translateAttribute = (
  attribute: string,
  issue: ZodIssueOptionalMessage
): string => {
  const existKey = translateModuleKeys.find((key) => {
    const tKey = `${key}.${toSnakeCase(attribute)}`;
    return t(tKey) != tKey;
  });
  const tKey = `${existKey}.${toSnakeCase(attribute)}`;
  if (!existKey) {
    console.warn(
      'not found key',
      translateModuleKeys,
      toSnakeCase(attribute),
      issue
    );
  }
  return existKey ? t(tKey) : 'このフィールド';
};

export const japaneseErrorMap = (
  issue: ZodIssueOptionalMessage,
  _ctx: ErrorMapCtx
): { message: string } => {
  let message: string;

  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === 'undefined' || issue.received === 'null') {
        message = t('errors.messages.required');
      } else if (issue.received === null || (issue.received as string) === '') {
        message = t('errors.messages.blank');
      } else if (issue.expected === 'integer' || issue.expected === 'bigint') {
        message = t('errors.messages.not_an_integer');
      } else if (issue.expected === 'number') {
        message = t('errors.messages.not_a_number');
      } else if (issue.expected === 'date') {
        message = t('errors.messages.invalid_date');
      } else {
        message = t('errors.messages.invalid');
      }
      break;
    case ZodIssueCode.unrecognized_keys:
      message = t('errors.unrecognized_keys', {
        keys: issue.keys.map((k) => `'${k}'`).join(', '),
      });
      break;
    case ZodIssueCode.invalid_union:
      message = t('errors.messages.invalid');
      break;
    case ZodIssueCode.invalid_union_discriminator:
      message = t('errors.messages.required_choice');
      break;
    case ZodIssueCode.invalid_enum_value:
      message = t('errors.messages.required_choice');
      break;
    case ZodIssueCode.invalid_date:
      message = t('errors.messages.invalid_date');
      break;
    case ZodIssueCode.invalid_string:
      message = t('errors.messages.invalid');
      break;
    case ZodIssueCode.too_small:
      message = issue.inclusive
        ? t('errors.messages.greater_than_or_equal_to', {
            count: issue.minimum as number,
          })
        : t('errors.messages.greater_than', { count: issue.minimum as number });
      if (issue.type === 'string') {
        // NOTE: string型ではlt, lteに対応していないためinclusiveを無視する
        message = t('errors.messages.too_short', {
          count: issue.minimum as number,
        });
      }
      break;
    case ZodIssueCode.too_big:
      message = issue.inclusive
        ? t('errors.messages.less_than_or_equal_to', {
            count: issue.maximum as number,
          })
        : t('errors.messages.less_than', { count: issue.maximum as number });
      if (issue.type === 'string') {
        // NOTE: string型ではlt, lteに対応していないためinclusiveを無視する
        message = t('errors.messages.too_long', {
          count: issue.maximum as number,
        });
      }
      break;
    case ZodIssueCode.custom:
      // NOTE: customバリデータのメッセージを判定するためにparamsにtypeプロパティを設定すること
      // typeプロパティが設定されていない場合はinvalidメッセージを表示する
      if (issue.params?.type === 'dayjs_field') {
        message = t('errors.messages.invalid_date');
      } else {
        message = issue.message ?? t('errors.messages.invalid');
      }
      break;
    default:
      return { message: _ctx.defaultError };
  }
  const lastPath = issue.path[issue.path.length - 1] as string;
  return { message: translateAttribute(lastPath ?? '', issue) + message };
};
