import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationJa from './ja.json';

const resources = {
  ja: {
    translation: translationJa,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ja',
    interpolation: {
      escapeValue: false, // react already safes from xss
      defaultVariables: {
        dataName: '',
      },
    },
  });

export const t = i18n.t;
export const tp = (key: string, options: object = {}) => t(key, options);
