import {
  CandidateGroup,
  NewWorkTaskWorkInstructionBundle,
  ValidationError,
  WorkInstructionNoBundleAllOfWorkTasksInner,
  WorkTaskBundleWorkTasksEmployee,
  WorkTaskListItem,
  WorkTaskWorkInstructionBundle,
  WorkTasksPlannedEmployeeBundle,
  instanceOfValidationError,
} from '@/api/models';
import { getServerError } from './rails_helper';
import dayjs, { Dayjs } from 'dayjs';

/**
 * null or undefinedの場合は空文字列を返す
 * それ以外はそのまま返す
 * @param value 文字列
 * @returns 文字列
 */
export function nullToEmpty(value: string | null | undefined) {
  return value === null || value === undefined ? '' : value;
}

/**
 * 正しい日付かどうかを判定する
 * @param value 日付
 * @returns 正しい場合はtrue、それ以外はfalse
 */
export function isValidDate(value: unknown): value is Date {
  return value instanceof Date && !isNaN(value.getTime());
}

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export type DeepNullable<T> = {
  [P in keyof T]: P extends object ? DeepNullable<T[P]> | null : T[P] | null;
};

/**
 * サーバーからのエラーを処理する
 * @param error サーバーからのエラー
 * @param handleError エラー処理した後のエラーメッセージ表示処理を行う関数
 * @return Promise<void>を返す
 */
export function standardServerErrorHandler(
  error: any,
  handleError: (message: string, errors: string[]) => void
) {
  return getServerError(error).then((serverError) => {
    if (serverError.code === 401) return;

    let message = serverError.message;
    let errors: string[] = [];
    if (instanceOfValidationError(serverError)) {
      const validationError = serverError as ValidationError;
      errors = validationError.errors;
    }

    handleError?.(message, errors);
  });
}

/**
 * 日付をフォーマットする
 * 本システムのデフォルトの日付フォーマットはYYYY/MM/DD
 * @param date Date型、Dayjs型、文字列型の日付
 * @returns YYYY/MM/DD形式の日付文字列 正しくない場合は空文字
 */
export function formatDate(date: Dayjs | string | null | undefined) {
  if (!date) {
    return '';
  }

  const value = dayjs(date);
  if (!value.isValid()) {
    return '';
  }
  return value.format('YYYY/MM/DD');
}

/**
 * リクエストパラメータ用に日付をフォーマットする
 * 本システムのデフォルトの日付フォーマットはYYYY-MM-DD
 * クエリパラメータの場合はYYYY-MM-DDのほうが見やすいため、こちらを使用する
 * @param date Date型、Dayjs型、文字列型の日付
 * @returns YYYY-MM-DD形式の日付文字列 正しくない場合はnull
 */
export function formatDateForRequest(date: Dayjs | string | null | undefined) {
  if (!date) {
    return date;
  }

  const value = dayjs(date);
  if (!value.isValid()) {
    return null;
  }
  return value.format('YYYY-MM-DD');
}

/**
 * 日付を和暦でフォーマットする
 * 本システムのデフォルトの日付フォーマットはYYYY年MM月DD (曜日)
 * @param date Date型、Dayjs型、文字列型の日付
 * @returns YYYY年MM月DD (曜日)形式の日付文字列  正しくない場合は空文字
 */
export function formatDateAsJaEra(date: Dayjs | string | null | undefined) {
  if (!date) {
    return '';
  }

  const value = dayjs(date);
  if (!value.isValid()) {
    return '';
  }
  return value.format('YYYY年MM月DD日 (dd)');
}

/**
 * 日付をYYYY-MM形式でフォーマットする
 * 月間カレンダーなどのクエリパラメータなどに使用する
 * @param date Date型、Dayjs型、文字列型の日付
 * @returns YYYY-MM形式の日付文字列
 */
export function formatDateForRequestAsYearMonth(
  date: Dayjs | string | null | undefined
) {
  return dayjs(date).format('YYYY-MM');
}

/**
 * 日付を和暦の年月形式でフォーマットする
 * この形式は、主に月間カレンダー表示に使用されます
 * @param date Date型、Dayjs型、文字列型の日付
 * @returns YYYY年MM月形式の日付文字列
 */
export function formatDateAsJaEraYearMonth(
  date: Dayjs | string | null | undefined
) {
  return dayjs(date).format('YYYY年MM月');
}

/**
 * 日付を0埋めなしの日（曜日）形式でフォーマットする
 * @param date Date型、Dayjs型、文字列型の日付
 * @returns D日（曜日）形式の日付文字列 正しくない場合は空文字
 */
export function formatDateAsJaDateWeekdayNoPadding(
  date: Dayjs | string | null | undefined
) {
  if (!date) {
    return '';
  }

  const value = dayjs(date);
  if (!value.isValid()) {
    return '';
  }

  return dayjs(date).format('D日 (dd)');
}

/**
 * 日付を0埋めなしの月日（曜日）形式でフォーマットする
 * @param date Date型、Dayjs型、文字列型の日付
 * @returns M月D日（曜日）形式の日付文字列 正しくない場合は空文字
 */
export function formatDateAsJaMonthDateWeekdayNoPadding(
  date: Dayjs | string | null | undefined
) {
  if (!date) {
    return '';
  }

  const value = dayjs(date);
  if (!value.isValid()) {
    return '';
  }

  return dayjs(date).format('M月D日 (dd)');
}

/**
 * 日付を0埋めなしの月日形式でフォーマットする
 * @param date Date型、Dayjs型、文字列型の日付
 * @returns M月D日形式の日付文字列 正しくない場合は空文字
 */
export function formatDateAsJaMonthDateNoPadding(
  date: Dayjs | string | null | undefined
) {
  if (!date) {
    return '';
  }

  const value = dayjs(date);
  if (!value.isValid()) {
    return '';
  }

  return dayjs(date).format('M月D日');
}

/**
 * 日付を0埋めありの月日（曜日）形式でフォーマットする
 * @param date Date型、Dayjs型、文字列型の日付
 * @returns M/D（曜日）形式の日付文字列 正しくない場合は空文字
 */
export function formatDateAsMonthDateWeekday(
  date: Dayjs | string | null | undefined
) {
  if (!date) {
    return '';
  }

  const value = dayjs(date);
  if (!value.isValid()) {
    return '';
  }

  return dayjs(date).format('MM/DD (ddd)');
}

/**
 * 日付をYYYY/MM/DD HH:MMの形式にする
 * @param date Date型、Dayjs型、文字列型の日付
 * @returns YYYY/MM/DD HH:MM形式の日付文字列 正しくない場合は空文字
 */
export function formatDateTime(date: Dayjs | string | null | undefined) {
  if (!date) {
    return '';
  }

  const value = dayjs(date);
  if (!value.isValid()) {
    return '';
  }
  return value.format('YYYY/MM/DD HH:mm');
}

/**
 * 日付をYYYYMMDDHHmmssの形式にする
 * @param date Date型、Dayjs型、文字列型の日付
 * @returns YYYYMMDDHHmmss形式の日付文字列 正しくない場合は空文字
 */
export function formatDateTimeAsUnseparated(
  date: Dayjs | string | null | undefined
) {
  if (!date) {
    return '';
  }

  const value = dayjs(date);
  if (!value.isValid()) {
    return '';
  }
  return value.format('YYYYMMDDHHmmss');
}

/**
 * URLのパスから日付を取得する
 * @param path パス
 * @param regexp パスの正規表現
 * @returns 正規表現にマッチする日付を返す、しない場合は本日の日付を返す
 */
export function parseDateFromURL(path: string, regexp: RegExp) {
  const urlDateString = regexp.exec(new URL(path).pathname)?.slice(1)[0];
  return urlDateString ? dayjs(urlDateString) : dayjs();
}

/**
 * undefinedのプロパティをnullにする
 * 用途はreact-hook-formはundefinedの場合は値を更新しないため、undefinedの場合はnullに変換する
 * @param obj 変換するオブジェクト
 * @param seen 再帰処理用のオブジェクト
 */

export function undefinedToNull(obj: any, seen = new WeakSet<any>()): void {
  if (obj === null || typeof obj !== 'object' || seen.has(obj)) return;

  seen.add(obj);

  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i] === undefined) {
        obj[i] = null;
      } else if (typeof obj[i] === 'object' && obj[i] !== null) {
        undefinedToNull(obj[i], seen);
      }
    }
  } else {
    for (const key in obj) {
      if (obj[key] === undefined) {
        obj[key] = null;
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        undefinedToNull(obj[key], seen);
      }
    }
  }
}

/**
 * 、指定した期間内の各日付を配列として返す
 * @param start 開始日
 * @param end 終了日
 * @returns 日付の配列
 */
export function eachDayOfInterval(start: dayjs.Dayjs, end: dayjs.Dayjs) {
  let day = dayjs(start);
  const endDay = dayjs(end);
  const dayArray = [];

  while (day.isBefore(endDay) || day.isSame(endDay, 'day')) {
    dayArray.push(day);
    day = day.add(1, 'day');
  }

  return dayArray;
}

/**
 * 指定された引数でdayjsを返す。ただしnull,undefinedはそのまま返す
 * @param date 日付
 * @returns dayjs
 */
export function createDayjs(
  date: dayjs.Dayjs | Date | string | undefined | null
) {
  if (date === null || date === undefined) {
    return date;
  }
  return dayjs(date);
}

/**
 * 月間カレンダーの範囲オブジェクトを返す。
 * @param month 月
 * @returns 月間カレンダーの範囲オブジェクト
 */
export function getMonthlyRange(month: Dayjs) {
  return {
    start: getMonthlyCalendarStartDate(month),
    end: getMonthlyCalendarEndDate(month),
  };
}

/**
 * 月間カレンダーの開始日を返す。
 * 日曜日の場合は前の週の月曜日を返す。
 * 他の曜日（月曜日〜土曜日）の場合は同週の月曜日を返す。
 * @param month 月
 * @returns 月間カレンダーの開始日
 */
export function getMonthlyCalendarStartDate(month: Dayjs) {
  const firstDateOfMonth = month.startOf('month');

  return firstDateOfMonth.day() === 0
    ? firstDateOfMonth.subtract(6, 'day')
    : firstDateOfMonth.day(1);
}

/**
 * 月間カレンダーの終了日を返す。
 * 月の最終日が日曜日の場合はその日を返す。
 * 他の曜日（月曜日〜土曜日）の場合は次週の日曜日を返す。
 * @param month 月
 * @returns 月間カレンダーの終了日
 */
export function getMonthlyCalendarEndDate(month: Dayjs) {
  const lastDateOfMonth = month.endOf('month');

  return lastDateOfMonth.day() === 0
    ? lastDateOfMonth
    : lastDateOfMonth.day(0).add(1, 'week');
}

/**
 * 受け取った文字列を括弧で囲んだ文字列にして返す
 * 空文字、null、undefinedの場合は空文字を返す
 * 例：'テスト' => '（テスト）'
 * @param value 文字列
 * @returns 文字列
 */
export function wrapWithParentheses(value: string | null | undefined) {
  return value ? `（${value}）` : '';
}

/**
 * 実績工数を表示様にフォーマットする
 * @param actualPersonMinuts 実績工数（分）
 * @returns 実績工数を◯時間◯分の形式にフォーマットした文字列
 */
export function formatActualPersonTime(actualPersonMinutes: number) {
  const actualPersonHours =
    actualPersonMinutes > 0 ? Math.floor(actualPersonMinutes / 60) : 0;
  actualPersonMinutes = actualPersonMinutes > 0 ? actualPersonMinutes % 60 : 0;

  let formattedString = `${actualPersonMinutes}分`;
  if (actualPersonHours !== 0) {
    formattedString = `${actualPersonHours}時間${formattedString}`;
  }

  return formattedString;
}

/**
 * sortPriority属性を持ったオブジェクトのソート用比較関数（昇順）
 */
type SortableObject = {
  sortPriority: number;
};
export function sortPriorityCompare(a: SortableObject, b: SortableObject) {
  return a.sortPriority - b.sortPriority;
}

/**
 * 作業タスクに紐付く作業担当者、作業予定者をソートする比較関数
 * ソート順は以下の通り
 *   グループID.昇順 > ソート優先度.昇順
 */
function sortEmployeesByGroupAndPriority(
  a: WorkTasksPlannedEmployeeBundle | WorkTaskBundleWorkTasksEmployee,
  b: WorkTasksPlannedEmployeeBundle | WorkTaskBundleWorkTasksEmployee
) {
  if (a.employee.groupId === b.employee.groupId) {
    return sortPriorityCompare(a.employee, b.employee);
  }
  return a.employee.groupId - b.employee.groupId;
}

/**
 * 作業担当者および作業予定者の並び順をソートする
 * @param workTask ソート前の作業タスク
 * @returns ソート後の作業タスク
 */
export function sortWorkTaskEmployees<
  T extends
    | WorkTaskWorkInstructionBundle
    | WorkInstructionNoBundleAllOfWorkTasksInner
    | NewWorkTaskWorkInstructionBundle
    | WorkTaskListItem
>(workTask: T) {
  let sortedWorkTasksEmployees: WorkTaskBundleWorkTasksEmployee[] = [];
  let sortedWorkTasksPlannedEmployees: WorkTasksPlannedEmployeeBundle[] = [];

  if (workTask.workTasksEmployees && workTask.workTasksEmployees.length > 0) {
    sortedWorkTasksEmployees = [...workTask.workTasksEmployees].sort(
      sortEmployeesByGroupAndPriority
    );
  }

  if (
    workTask.workTasksPlannedEmployees &&
    workTask.workTasksPlannedEmployees.length > 0
  ) {
    sortedWorkTasksPlannedEmployees = [
      ...workTask.workTasksPlannedEmployees,
    ].sort(sortEmployeesByGroupAndPriority);
  }

  return {
    ...workTask,
    workTasksEmployees: sortedWorkTasksEmployees,
    workTasksPlannedEmployees: sortedWorkTasksPlannedEmployees,
  };
}

/**
 * グループの並び順をソートする
 * @param groups ソート前のグループ
 * @returns ソート後のグループ
 */
export function sortGroups(groups: CandidateGroup[]) {
  return groups.slice().sort(sortPriorityCompare);
}

/**
 * 得意先名を表示用にフォーマットする
 * @param clientName 得意先名
 * @param clientNameAlias 得意先名別名
 * @returns 得意先名別名がある場合は「得意先名別名（得意先名）」、ない場合は「得意先名」を返す
 */
export function formatClientName(
  clientName: string,
  clientNameAlias?: string | null
) {
  if (!clientNameAlias) {
    return clientName;
  }
  return `${clientNameAlias}（${clientName}）`;
}
