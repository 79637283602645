/* eslint-disable @typescript-eslint/naming-convention */

import React from 'react';
import { ConfigProvider, theme } from 'antd';

const { useToken } = theme;

interface GlobalThemeProps {
  children: React.ReactNode;
}

export const GlobalTheme: React.FC<GlobalThemeProps> = ({ children }) => {
  const { token } = useToken();

  const customTheme = {
    token: {
      colorBgLayout: '#eaeaea',
    },
    components: {
      Select: {
        controlItemBgHover: token.controlItemBgActive,
      },
      TreeSelect: {
        controlItemBgHover: token.controlItemBgActive,
      },
      Table: {
        colorFillAlter: '#f5f5f5',
        colorFillContent: '#f5f5f5',
        colorFillSecondary: '#f5f5f5',
      },
    },
  };

  return <ConfigProvider theme={customTheme}>{children}</ConfigProvider>;
};
