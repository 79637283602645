import { querystring } from './rails_helper';
import { Dayjs } from 'dayjs';

export function newSessionPath(): string {
  const url = `/employees/sign_in`;

  return url;
}

export const defaultIndexWorkInstructionPage = 1;

export const defaultIndexWorkInstructionPageSize = 100;

export function indexWorkInstructionPath(
  page: number = 1,
  pageSize: number = 100,
  q?: {
    depotIdEq?: number;
    districtIdIn?: string[] | null;
    employeeIdEntriedShow?: boolean;
    employeeIdIn?: string[] | null;
    propertyCodeCont?: string;
    propertyNameCont?: string;
    workInstructionNoWithoutHyphenCont?: string;
    clientNameOrClientNameAliasCont?: string;
    groupIdEq?: string | null;
    groupIdEntriedShow?: boolean;
    uncompleted?: boolean;
    completionDateEntriedShow?: boolean;
    completionDateGteq?: Dayjs | null;
    completionDateLteq?: Dayjs | null;
    cancellationDateEntriedShow?: boolean;
    cancellationDateGteq?: Dayjs | null;
    cancellationDateLteq?: Dayjs | null;
    createdAtGteq?: Dayjs | null;
    createdAtLteq?: Dayjs | null;
    workDateStatusEq?: string;
    workStartDate?: Dayjs | null;
    workEndDate?: Dayjs | null;
    workStartDateAndWorkEndDateNull?: boolean;
    workTaskSort?: string;
    workTaskInterestedEmployeeId?: number | null;
    progressStatusIn?: ('unstarted' | 'doing' | 'completed' | 'aborted')[];
    propertyNameOrClientNameOrClientNameAliasOrWorkInstructionNoWithoutHyphenCont?: string;
    workTaskStatus?: string;
    groupDepotIdEq?: number;
    groupDepotIdEntriedShow?: boolean;
  }
): string {
  const url = `/work_instructions`;

  const queryParameters: any = {};

  if (page !== undefined) {
    queryParameters['page'] = page;
  }

  if (pageSize !== undefined) {
    queryParameters['pageSize'] = pageSize;
  }

  if (q !== undefined) {
    queryParameters['q'] = q;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export const defaultShowWorkInstructionPathTabKey = 'baseInfo';

export function showWorkInstructionPath(
  id: number,
  tabKey: 'baseInfo' | 'workTasks' | 'workInstructionExcelBooks' = 'baseInfo'
): string {
  const url = `/work_instructions/${id}`;

  const queryParameters: any = {};

  if (tabKey !== undefined) {
    queryParameters['tabKey'] = tabKey;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function newWorkInstructionPath(id?: number): string {
  const url = `/work_instructions/new`;

  const queryParameters: any = {};

  if (id !== undefined) {
    queryParameters['id'] = id;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function IndexWorkInstructionWorkInstructionNoPath(
  work_instruction_id: number
): string {
  const url = `/work_instructions/${work_instruction_id}/work_instruction_nos`;

  return url;
}

export function downloadWorkInstructionWorkInstructionNoPath(
  work_instruction_id: number,
  id: number
): string {
  const url = `/work_instructions/${work_instruction_id}/work_instruction_nos/${id}/download`;

  return url;
}

export function indexWorkTaskMultiCreationPath(
  work_instruction_id: number,
  id: number
): string {
  const url = `/work_instructions/${work_instruction_id}/work_instruction_nos/${id}/work_task_multi_creations`;

  return url;
}

export function newWorkTaskPath(
  work_instruction_id: number,
  work_instruction_no_id: number,
  id?: number
): string {
  const url = `/work_instructions/${work_instruction_id}/work_instruction_nos/${work_instruction_no_id}/work_tasks/new`;

  const queryParameters: any = {};

  if (id !== undefined) {
    queryParameters['id'] = id;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export const defaultIndexWorkTaskPathPage = 1;

export const defaultIndexWorkTaskPathPageSize = 100;

export function indexWorkTaskPath(
  page: number = 1,
  pageSize: number = 100,
  q?: {
    depotIdEq?: number;
    districtIdIn?: string[] | null;
    employeeIdEntriedShow?: boolean;
    employeeIdIn?: string[] | null;
    propertyCodeCont?: string;
    propertyNameCont?: string;
    workInstructionNoWithoutHyphenCont?: string;
    clientNameOrClientNameAliasCont?: string;
    groupIdEq?: string | null;
    groupIdEntriedShow?: boolean;
    uncompleted?: boolean;
    completionDateEntriedShow?: boolean;
    completionDateGteq?: Dayjs | null;
    completionDateLteq?: Dayjs | null;
    cancellationDateEntriedShow?: boolean;
    cancellationDateGteq?: Dayjs | null;
    cancellationDateLteq?: Dayjs | null;
    createdAtGteq?: Dayjs | null;
    createdAtLteq?: Dayjs | null;
    workDateStatusEq?: string;
    workStartDate?: Dayjs | null;
    workEndDate?: Dayjs | null;
    workStartDateAndWorkEndDateNull?: boolean;
    workTaskSort?: string;
    workTaskInterestedEmployeeId?: number | null;
    progressStatusIn?: ('unstarted' | 'doing' | 'completed' | 'aborted')[];
    propertyNameOrClientNameOrClientNameAliasOrWorkInstructionNoWithoutHyphenCont?: string;
    workTaskStatus?: string;
    groupDepotIdEq?: number;
    groupDepotIdEntriedShow?: boolean;
  }
): string {
  const url = `/work_tasks`;

  const queryParameters: any = {};

  if (page !== undefined) {
    queryParameters['page'] = page;
  }

  if (pageSize !== undefined) {
    queryParameters['pageSize'] = pageSize;
  }

  if (q !== undefined) {
    queryParameters['q'] = q;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function editWorkInstructionWorkTaskPath(
  work_instruction_id: number,
  id: number
): string {
  const url = `/work_instructions/${work_instruction_id}/work_tasks/${id}/edit`;

  return url;
}

export function dailyPath(
  target_date: string,
  q?: {
    depotIdEq?: number;
    districtIdIn?: string[] | null;
    employeeIdEntriedShow?: boolean;
    employeeIdIn?: string[] | null;
    propertyCodeCont?: string;
    propertyNameCont?: string;
    workInstructionNoWithoutHyphenCont?: string;
    clientNameOrClientNameAliasCont?: string;
    groupIdEq?: string | null;
    groupIdEntriedShow?: boolean;
    uncompleted?: boolean;
    completionDateEntriedShow?: boolean;
    completionDateGteq?: Dayjs | null;
    completionDateLteq?: Dayjs | null;
    cancellationDateEntriedShow?: boolean;
    cancellationDateGteq?: Dayjs | null;
    cancellationDateLteq?: Dayjs | null;
    createdAtGteq?: Dayjs | null;
    createdAtLteq?: Dayjs | null;
    workDateStatusEq?: string;
    workStartDate?: Dayjs | null;
    workEndDate?: Dayjs | null;
    workStartDateAndWorkEndDateNull?: boolean;
    workTaskSort?: string;
    workTaskInterestedEmployeeId?: number | null;
    progressStatusIn?: ('unstarted' | 'doing' | 'completed' | 'aborted')[];
    propertyNameOrClientNameOrClientNameAliasOrWorkInstructionNoWithoutHyphenCont?: string;
    workTaskStatus?: string;
    groupDepotIdEq?: number;
    groupDepotIdEntriedShow?: boolean;
  }
): string {
  const url = `/work_schedules/${target_date}/daily`;

  const queryParameters: any = {};

  if (q !== undefined) {
    queryParameters['q'] = q;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function dailyWorkComparisonPath(
  target_date: string,
  q?: {
    depotIdEq?: number;
    districtIdIn?: string[] | null;
    employeeIdEntriedShow?: boolean;
    employeeIdIn?: string[] | null;
    propertyCodeCont?: string;
    propertyNameCont?: string;
    workInstructionNoWithoutHyphenCont?: string;
    clientNameOrClientNameAliasCont?: string;
    groupIdEq?: string | null;
    groupIdEntriedShow?: boolean;
    uncompleted?: boolean;
    completionDateEntriedShow?: boolean;
    completionDateGteq?: Dayjs | null;
    completionDateLteq?: Dayjs | null;
    cancellationDateEntriedShow?: boolean;
    cancellationDateGteq?: Dayjs | null;
    cancellationDateLteq?: Dayjs | null;
    createdAtGteq?: Dayjs | null;
    createdAtLteq?: Dayjs | null;
    workDateStatusEq?: string;
    workStartDate?: Dayjs | null;
    workEndDate?: Dayjs | null;
    workStartDateAndWorkEndDateNull?: boolean;
    workTaskSort?: string;
    workTaskInterestedEmployeeId?: number | null;
    progressStatusIn?: ('unstarted' | 'doing' | 'completed' | 'aborted')[];
    propertyNameOrClientNameOrClientNameAliasOrWorkInstructionNoWithoutHyphenCont?: string;
    workTaskStatus?: string;
    groupDepotIdEq?: number;
    groupDepotIdEntriedShow?: boolean;
  }
): string {
  const url = `/work_schedules/${target_date}/daily_work_comparison`;

  const queryParameters: any = {};

  if (q !== undefined) {
    queryParameters['q'] = q;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function weeklyPath(
  target_date: string,
  q?: {
    depotIdEq?: number;
    districtIdIn?: string[] | null;
    employeeIdEntriedShow?: boolean;
    employeeIdIn?: string[] | null;
    propertyCodeCont?: string;
    propertyNameCont?: string;
    workInstructionNoWithoutHyphenCont?: string;
    clientNameOrClientNameAliasCont?: string;
    groupIdEq?: string | null;
    groupIdEntriedShow?: boolean;
    uncompleted?: boolean;
    completionDateEntriedShow?: boolean;
    completionDateGteq?: Dayjs | null;
    completionDateLteq?: Dayjs | null;
    cancellationDateEntriedShow?: boolean;
    cancellationDateGteq?: Dayjs | null;
    cancellationDateLteq?: Dayjs | null;
    createdAtGteq?: Dayjs | null;
    createdAtLteq?: Dayjs | null;
    workDateStatusEq?: string;
    workStartDate?: Dayjs | null;
    workEndDate?: Dayjs | null;
    workStartDateAndWorkEndDateNull?: boolean;
    workTaskSort?: string;
    workTaskInterestedEmployeeId?: number | null;
    progressStatusIn?: ('unstarted' | 'doing' | 'completed' | 'aborted')[];
    propertyNameOrClientNameOrClientNameAliasOrWorkInstructionNoWithoutHyphenCont?: string;
    workTaskStatus?: string;
    groupDepotIdEq?: number;
    groupDepotIdEntriedShow?: boolean;
  }
): string {
  const url = `/work_schedules/${target_date}/weekly`;

  const queryParameters: any = {};

  if (q !== undefined) {
    queryParameters['q'] = q;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function monthlyPath(
  target_month: string,
  q?: {
    depotIdEq?: number;
    districtIdIn?: string[] | null;
    employeeIdEntriedShow?: boolean;
    employeeIdIn?: string[] | null;
    propertyCodeCont?: string;
    propertyNameCont?: string;
    workInstructionNoWithoutHyphenCont?: string;
    clientNameOrClientNameAliasCont?: string;
    groupIdEq?: string | null;
    groupIdEntriedShow?: boolean;
    uncompleted?: boolean;
    completionDateEntriedShow?: boolean;
    completionDateGteq?: Dayjs | null;
    completionDateLteq?: Dayjs | null;
    cancellationDateEntriedShow?: boolean;
    cancellationDateGteq?: Dayjs | null;
    cancellationDateLteq?: Dayjs | null;
    createdAtGteq?: Dayjs | null;
    createdAtLteq?: Dayjs | null;
    workDateStatusEq?: string;
    workStartDate?: Dayjs | null;
    workEndDate?: Dayjs | null;
    workStartDateAndWorkEndDateNull?: boolean;
    workTaskSort?: string;
    workTaskInterestedEmployeeId?: number | null;
    progressStatusIn?: ('unstarted' | 'doing' | 'completed' | 'aborted')[];
    propertyNameOrClientNameOrClientNameAliasOrWorkInstructionNoWithoutHyphenCont?: string;
    workTaskStatus?: string;
    groupDepotIdEq?: number;
    groupDepotIdEntriedShow?: boolean;
  }
): string {
  const url = `/work_schedules/${target_month}/monthly`;

  const queryParameters: any = {};

  if (q !== undefined) {
    queryParameters['q'] = q;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export const defaultIndexVersionNotificationPathPage = 1;

export const defaultIndexVersionNotificationPathPageSize = 100;

export function indexVersionNotificationPath(
  page: number = 1,
  pageSize: number = 100
): string {
  const url = `/version_notifications`;

  const queryParameters: any = {};

  if (page !== undefined) {
    queryParameters['page'] = page;
  }

  if (pageSize !== undefined) {
    queryParameters['pageSize'] = pageSize;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function newMobileSessionPath(): string {
  const url = `/mobile/employees/sign_in`;

  return url;
}

export const defaultShowMobileWorkTaskActiveTab = 'workTask';

export function showMobileWorkTaskPath(
  id: number,
  previousPageName?: 'workSchedulesDaily' | 'workTasks',
  previousPageParams?: string,
  activeTab: 'workTask' | 'file' | 'workLog' = 'workTask'
): string {
  const url = `/mobile/work_tasks/${id}`;

  const queryParameters: any = {};

  if (previousPageName !== undefined) {
    queryParameters['previousPageName'] = previousPageName;
  }

  if (previousPageParams !== undefined) {
    queryParameters['previousPageParams'] = previousPageParams;
  }

  if (activeTab !== undefined) {
    queryParameters['activeTab'] = activeTab;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function indexProfilePath(): string {
  const url = `/mobile/profiles`;

  return url;
}

export const defaultDailyMobileWorkScheduleQ = {};

export function dailyMobileWorkSchedulePath(
  target_date: string,
  q: {
    groupIdOrWorkInstructionNoWorkInstructionEmployeeGroupIdIn?: number[];
    workTaskInterestedEmployeeId?: number | null;
    progressStatus?: 'unstarted' | 'doing' | 'completed' | 'aborted';
  } = {}
): string {
  const url = `/mobile/work_schedules/${target_date}/daily`;

  const queryParameters: any = {};

  if (q !== undefined) {
    queryParameters['q'] = q;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function editMobileWorkTaskPath(
  id: number,
  previousPageName?: 'workSchedulesDaily' | 'workTasks',
  previousPageParams?: string
): string {
  const url = `/mobile/work_tasks/${id}/edit`;

  const queryParameters: any = {};

  if (previousPageName !== undefined) {
    queryParameters['previousPageName'] = previousPageName;
  }

  if (previousPageParams !== undefined) {
    queryParameters['previousPageParams'] = previousPageParams;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function newMobileWorkTaskWorkLogPath(
  work_task_id: number,
  previousPageName?: 'workSchedulesDaily' | 'workTasks',
  previousPageParams?: string
): string {
  const url = `/mobile/work_tasks/${work_task_id}/work_logs/new`;

  const queryParameters: any = {};

  if (previousPageName !== undefined) {
    queryParameters['previousPageName'] = previousPageName;
  }

  if (previousPageParams !== undefined) {
    queryParameters['previousPageParams'] = previousPageParams;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function editMobileWorkTaskWorkLogPath(
  work_task_id: number,
  id: number,
  previousPageName?: 'workSchedulesDaily' | 'workTasks',
  previousPageParams?: string
): string {
  const url = `/mobile/work_tasks/${work_task_id}/work_logs/${id}/edit`;

  const queryParameters: any = {};

  if (previousPageName !== undefined) {
    queryParameters['previousPageName'] = previousPageName;
  }

  if (previousPageParams !== undefined) {
    queryParameters['previousPageParams'] = previousPageParams;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function mobileIndexWorkTaskPath(q?: {
  depotIdEq?: number;
  districtIdIn?: string[] | null;
  employeeIdEntriedShow?: boolean;
  employeeIdIn?: string[] | null;
  propertyCodeCont?: string;
  propertyNameCont?: string;
  workInstructionNoWithoutHyphenCont?: string;
  clientNameOrClientNameAliasCont?: string;
  groupIdEq?: string | null;
  groupIdEntriedShow?: boolean;
  uncompleted?: boolean;
  completionDateEntriedShow?: boolean;
  completionDateGteq?: Dayjs | null;
  completionDateLteq?: Dayjs | null;
  cancellationDateEntriedShow?: boolean;
  cancellationDateGteq?: Dayjs | null;
  cancellationDateLteq?: Dayjs | null;
  createdAtGteq?: Dayjs | null;
  createdAtLteq?: Dayjs | null;
  workDateStatusEq?: string;
  workStartDate?: Dayjs | null;
  workEndDate?: Dayjs | null;
  workStartDateAndWorkEndDateNull?: boolean;
  workTaskSort?: string;
  workTaskInterestedEmployeeId?: number | null;
  progressStatusIn?: ('unstarted' | 'doing' | 'completed' | 'aborted')[];
  propertyNameOrClientNameOrClientNameAliasOrWorkInstructionNoWithoutHyphenCont?: string;
  workTaskStatus?: string;
  groupDepotIdEq?: number;
  groupDepotIdEntriedShow?: boolean;
}): string {
  const url = `/mobile/work_tasks`;

  const queryParameters: any = {};

  if (q !== undefined) {
    queryParameters['q'] = q;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}

export function mobileSearchWorkTaskPath(q?: {
  depotIdEq?: number;
  districtIdIn?: string[] | null;
  employeeIdEntriedShow?: boolean;
  employeeIdIn?: string[] | null;
  propertyCodeCont?: string;
  propertyNameCont?: string;
  workInstructionNoWithoutHyphenCont?: string;
  clientNameOrClientNameAliasCont?: string;
  groupIdEq?: string | null;
  groupIdEntriedShow?: boolean;
  uncompleted?: boolean;
  completionDateEntriedShow?: boolean;
  completionDateGteq?: Dayjs | null;
  completionDateLteq?: Dayjs | null;
  cancellationDateEntriedShow?: boolean;
  cancellationDateGteq?: Dayjs | null;
  cancellationDateLteq?: Dayjs | null;
  createdAtGteq?: Dayjs | null;
  createdAtLteq?: Dayjs | null;
  workDateStatusEq?: string;
  workStartDate?: Dayjs | null;
  workEndDate?: Dayjs | null;
  workStartDateAndWorkEndDateNull?: boolean;
  workTaskSort?: string;
  workTaskInterestedEmployeeId?: number | null;
  progressStatusIn?: ('unstarted' | 'doing' | 'completed' | 'aborted')[];
  propertyNameOrClientNameOrClientNameAliasOrWorkInstructionNoWithoutHyphenCont?: string;
  workTaskStatus?: string;
  groupDepotIdEq?: number;
  groupDepotIdEntriedShow?: boolean;
}): string {
  const url = `/mobile/work_tasks/search`;

  const queryParameters: any = {};

  if (q !== undefined) {
    queryParameters['q'] = q;
  }

  const queryParams = querystring(queryParameters);
  if (queryParams) {
    return url + '?' + queryParams;
  }

  return url;
}
