/* tslint:disable */
/* eslint-disable */
/**
 * 福間商事向け工程管理システム
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadAllVersionNotification200Response
 */
export interface ReadAllVersionNotification200Response {
    /**
     * 
     * @type {number}
     * @memberof ReadAllVersionNotification200Response
     */
    updatedCount: number;
}

/**
 * Check if a given object implements the ReadAllVersionNotification200Response interface.
 */
export function instanceOfReadAllVersionNotification200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "updatedCount" in value;

    return isInstance;
}

export function ReadAllVersionNotification200ResponseFromJSON(json: any): ReadAllVersionNotification200Response {
    return ReadAllVersionNotification200ResponseFromJSONTyped(json, false);
}

export function ReadAllVersionNotification200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadAllVersionNotification200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedCount': json['updatedCount'],
    };
}

export function ReadAllVersionNotification200ResponseToJSON(value?: ReadAllVersionNotification200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updatedCount': value.updatedCount,
    };
}

