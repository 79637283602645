import deepFreeze from 'deep-freeze-es6';

const spacingUnit = 8;
export const SPACING = deepFreeze({
  xxxs: spacingUnit * 0.25,
  xxs: spacingUnit * 0.5,
  xs: spacingUnit,
  sm: spacingUnit * 2,
  md: spacingUnit * 3,
  lg: spacingUnit * 4,
  xl: spacingUnit * 5,
  xxl: spacingUnit * 6,
});
export const SPACING_PX = deepFreeze(
  Object.fromEntries(
    Object.entries(SPACING).map(([key, value]) => [key, `${value}px`])
  )
);
export const ICON_SIZES = deepFreeze({
  small: 16,
  mediumSmall: 20,
  medium: 24,
  large: 32,
  xlarge: 48,
});

export const COLORS = deepFreeze({
  holidayBg: '#f2dcdc',
});
